






import { Component, Mixins } from 'vue-property-decorator'
import { AsyncComponent, VueConstructor } from 'vue'

import { StructureConfigurable } from '../../../../support/mixins'

import { AbstractModal } from '../../../shared/organisms/AbstractModal'
import { Loader } from '../../../shared/molecules/Loader'
import { RecommendationAction } from '../../../products/contracts/repositories'
import { SuggestedProductsMixin } from '../../../shared/mixins/suggestedProducts.mixin'

import {
  ADDED_TO_CART_MODAL_COMPONENT_CONFIG_MAP,
  ADDED_TO_CART_MODAL_COMPONENT_KEY, addedToCartModalComponentRegistry,
  AddedToCartModalConfig
} from './AddedToCartModal.config'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 * @author Filip Rurak <filip.rurak@movecloser.pl> (edited)
 */
@Component<AddedToCartModal>({
  name: 'AddedToCartModal',
  components: {
    Loader
  },
  created () {
    this.config = this.getComponentConfig(ADDED_TO_CART_MODAL_COMPONENT_KEY, { ...ADDED_TO_CART_MODAL_COMPONENT_CONFIG_MAP })

    /**
     * @inheritDoc
     */
    // TODO change to RecommendationAction.AddToCart
    this.loadSuggestedProducts(this.payload.product.sku, this.action)
  }
})
export class AddedToCartModal extends Mixins(AbstractModal, StructureConfigurable, SuggestedProductsMixin) {
  protected config!: AddedToCartModalConfig

  public get action (): RecommendationAction {
    return this.getConfigProperty<RecommendationAction>('action')
  }

  public get component (): VueConstructor | AsyncComponent {
    return addedToCartModalComponentRegistry[this.getConfigProperty<string>('layout')]
  }
}

export default AddedToCartModal
